export const getCookie = (name: string) => {
  var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)")
  return v ? v[2] : null
}

export const setCookie = (name, value, milliseconds) => {
  var d = new Date()
  d.setTime(d.getTime() + milliseconds)
  document.cookie = name + "=" + value + ";path=/;SameSite=Strict;expires=" + d.toUTCString()
}

export const deleteCookie = (name) => {
  setCookie(name, "", -1)
}

import classNames from "classnames"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"

interface Props {
  isSubscribed: boolean
  cookies: {
    newsletter?: any
  }
  email: string
  isSubmitting: boolean
  setEmail: Dispatch<SetStateAction<string>>
  error: any
}

const NewsLetterSubscription = ({ isSubscribed, cookies, email, isSubmitting, setEmail, error }: Props) => {
  // const [windowsDefined, setWindowsDefined] = useState<boolean>()
  // useEffect(() => {
  //   setWindowsDefined(typeof window !== "undefined")
  // }, [])
  // if (!windowsDefined) return null

  return (
    <div>
      {isSubscribed || cookies.newsletter == "true" ? (
        <div className="fs-4" style={{ color: "#FFF" }}>
          <hr />
          Yay! Your Subscribed 👍🏻
          <hr />
        </div>
      ) : (
        <div className="input-group has-validation">
          <input
            type="email"
            value={email}
            disabled={isSubmitting}
            onChange={(e) => setEmail(e.target.value)}
            className={classNames("form-control", {
              "is-invalid": error != null,
            })}
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            placeholder="Enter your email"
            aria-describedby="subscribeFeedback"
          />
          <span className="input-group-btn">
            <button className="btn btn-theme" type="submit">
              {isSubmitting ? (
                <div>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {" Subscribing"}
                </div>
              ) : (
                "Subscribe"
              )}
            </button>
          </span>
          {error && (
            <div id="subscribeFeedback" className="fs-5 invalid-feedback" style={{ paddingTop: "20px", color: "#FFF" }}>
              {error}
              <hr />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

/** export */
export default NewsLetterSubscription

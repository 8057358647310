import React from "react"
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  imageTitle: string
  image: string
  colorPalette: ColorPalette
}

/** const */
const HeroMiddleSection: React.FC<Props> = (props) => {
  return (
    <div
      className="container-fluid text-center hero"
      style={{
        color: "#FFF",
        background: `linear-gradient(${props.colorPalette.cardAccentTop} 30%, ${props.colorPalette.cardAccentTop} 70%)`,
        borderTop: "2px solid #FFFFFF",
      }}
    >
      <div className="container" data-aos="fade">
        <div className="col-sm-12 mt-5">
          <img
            loading="eager"
            className="mw-100 h-auto"
            width="800"
            height="400"
            src={props.image}
            alt={props.imageTitle}
          />
        </div>
      </div>
    </div>
  )
}

/** export */
export default HeroMiddleSection

import React, { useState } from "react"
import { ColorPalette } from "../Theme"
import { globalHistory } from "@reach/router"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useCookies } from "react-cookie"
import NewsLetterSubscription from "./NewsLetterSubscription"
interface Props {
  colorPalette: ColorPalette
}

const NewsletterSignup: React.FC<Props> = (props) => {
  const [cookies, setCookie] = useCookies(["newsletter"])
  const [error, setError] = useState(null)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)
  const [email, setEmail] = useState("")

  const path = globalHistory.location.pathname

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitting(true)
    setError(null)

    addToMailchimp(email, {
      PATHNAME: path,
    })
      .then((data) => {
        if (data.result == "success") {
          setIsSubscribed(true)
          setSubmitting(false)
          setCookie("newsletter", "true", { path: "/" })
        } else if (/is already subscribed/gi.test(data.msg)) {
          setIsSubscribed(true)
          setSubmitting(false)
          setCookie("newsletter", "true", { path: "/" })
        } else {
          setSubmitting(false)
          setError("Oh No an Error! Try again.")
        }
      })
      .catch(() => {
        setSubmitting(false)
        setError("Oh No an Error! Try again.")
      })
  }

  return (
    <section
      className="home-newsletter"
      style={{
        background: `linear-gradient(${props.colorPalette.heroRibbon} 30%, ${props.colorPalette.heroRibbon} 70%)`,
        color: `${props.colorPalette.sectionText}`,
      }}
    >
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="single">
                <h3 className="newsletterTitle">
                  <label htmlFor="email">Keep up to date on all things Norhart</label>
                </h3>
                <NewsLetterSubscription
                  email={email}
                  error={error}
                  setEmail={setEmail}
                  cookies={cookies}
                  isSubmitting={isSubmitting}
                  isSubscribed={isSubscribed}
                />
              </div>
              <h6 className="newsletterContent ps-1 pe-1">
                Join the family and receive updates, special offers, and more.
              </h6>
            </div>
          </div>
        </div>
      </form>
    </section>
  )
}

/** export */
export default NewsletterSignup

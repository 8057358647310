import React, { useState, useRef, useEffect } from "react"
import useCurrentWidth from "../hooks/use-current-width"

interface Props {
  items: Array<{
    label: string
    value: string
    ref?: any
  }>

  onChangeSegment: (value: string) => void
  defaultIndex?: number
}

/** const */
const SegmentedControl: React.FC<Props> = ({ items, onChangeSegment, defaultIndex = 0 }) => {
  let width = useCurrentWidth()
  const [activeIndex, setActiveIndex] = useState(defaultIndex)
  const segmentedControlRef = useRef<HTMLDivElement>()

  for (const item of items) {
    item.ref = useRef()
  }

  useEffect(() => {
    const activeSegmentRef = items[activeIndex].ref
    const { offsetWidth, offsetLeft } = activeSegmentRef.current
    const { style } = segmentedControlRef.current

    style.setProperty("--highlight-width", `${offsetWidth}px`)
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`)
  }, [activeIndex, onChangeSegment, items])

  useEffect(() => {
    const activeSegmentRef = items[activeIndex].ref
    const { offsetWidth, offsetLeft } = activeSegmentRef.current
    const { style } = segmentedControlRef.current

    style.setProperty("--highlight-width", `${offsetWidth}px`)
    style.setProperty("--highlight-x-pos", `${offsetLeft}px`)
  }, [width])

  const onInputChange = (value, index) => {
    setActiveIndex(index)
    onChangeSegment(value)
  }

  return (
    <div className="segmented-control" ref={segmentedControlRef}>
      <span className="segmented-control-selection notransition"></span>
      <div className="segmented-control-inner">
        {items.map((item, index) => {
          return (
            <div ref={item.ref} key={`${item.value}-segmentedControlItem`} className="segmented-control-item">
              <input
                key={index}
                type="radio"
                id={`${item.value}-segmentedControlItem`}
                value={item.value}
                onChange={() => {
                  onInputChange(item.value, index)
                }}
                checked={index === activeIndex}
              />
              <label htmlFor={`${item.value}-segmentedControlItem`}>
                <span>{item.label}</span>
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

/** export */
export default SegmentedControl

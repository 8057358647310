import { useEffect, useState } from "react"

const getSessionItem = (key) => {
  if (typeof sessionStorage !== "undefined") {
    return sessionStorage.getItem(key)
  }
  return null
}
const setSessionItem = (key, value) => {
  if (typeof sessionStorage !== "undefined") {
    sessionStorage.setItem(key, value)
    window.dispatchEvent(new CustomEvent("sessionItemChange", { detail: key }))
  }
}
const removeSessionItem = (key) => {
  if (typeof sessionStorage !== "undefined") {
    sessionStorage.removeItem(key)
    window.dispatchEvent(new CustomEvent("sessionItemChange", { detail: key }))
  }
}
const useSessionItem = (key, defaultValue) => {
  const [value, setValue] = useState(getSessionItem(key) || defaultValue)
  const listener = (event) => {
    if (event.detail === key) {
      setValue(getSessionItem(key))
    }
  }
  useEffect(() => {
    window.addEventListener("sessionItemChange", listener)
    return () => {
      window.removeEventListener("sessionItemChange", listener)
    }
  })
  return value
}
export { getSessionItem, setSessionItem, removeSessionItem, useSessionItem }

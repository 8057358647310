import { getSessionItem, setSessionItem } from "./session"

const pushOnce = (event) => {
  if (!getSessionItem("tag-manager-" + event.event)) {
    if (typeof window !== "undefined") {
      //record that the event has been sent once to prevent it from being sent again
      setSessionItem("tag-manager-" + event.event, "true")
      if (!window.dataLayer) {
        window.dataLayer = []
      }
      //tag manager sometimes takes the variables from the previous event
      //this only exists to prevent that collision
      window.dataLayer.push({ event: "clear", units: null })
      //send the actual event to tag manager
      window.dataLayer.push(event)
    }
  }
}

const push = (event) => {
  if (typeof window !== "undefined") {
    if (!window.dataLayer) {
      window.dataLayer = []
    }
    window.dataLayer.push(event)
  }
}
/** export */
export default { pushOnce, push }

declare global {
  interface Window {
    dataLayer: any
  }
}

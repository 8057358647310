import { useState, useEffect } from "react"

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

function useCurrentWidth(debounce = false) {
  let [width, setWidth] = useState(typeof window !== "undefined" ? getWidth() : undefined)

  useEffect(() => {
    let timeoutId = null
    const resizeListener = () => {
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => setWidth(getWidth()), debounce ? 150 : 0)
    }

    window.addEventListener("resize", resizeListener)

    return () => {
      window.removeEventListener("resize", resizeListener)
    }
  }, [])

  return width
}

/** export */
export default useCurrentWidth

import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ColorPalette } from "../Theme"
import SegmentedControl from "../SegmentedControl"
import { capitalize } from "../../utils/string"

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import NorhartCircleIcon from "../../../assets/norhart-circle-icon.svg"
import { getCookie } from "../../utils/cookie"
import tagManager from "../../utils/tag-manager"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

interface State {
  isLoaded: boolean
  segmentControlData: any[]
  segmentedJobsList: any
  jobs: any[]
}

class CareerListingSection extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isLoaded: false,
      segmentControlData: [],
      segmentedJobsList: {
        all: [],
      },
      jobs: [],
    }
  }

  private segmentJobs = (jobs: any[]) => {
    const segmentedJobs = {
      all: jobs,
    }
    // Segment "Hiring Bonus jobs"
    const hiringBonusJobs = jobs.filter((job) => {
      return (job.title?.match(/hiring bonus/i) || []).length > 0
    })
    if (hiringBonusJobs.length > 0) {
      segmentedJobs["hiring bonus"] = hiringBonusJobs
    }
    // // Segment Jobs by Industry
    // jobs.forEach(job => {
    //   const key = job.industry.toLowerCase()
    //   const relatedSegment = segmentedJobs[key] || []
    //   relatedSegment.push(job)
    //   segmentedJobs[key] = relatedSegment
    // })
    return segmentedJobs
  }

  private generateUIDataFromSegmentedJobs = (segmentedJobs: any): [any[], any] => {
    const segmentKeys = Object.keys(segmentedJobs) || []
    const segmentControlData = []
    const segmentedJobsList = {
      all: [],
    }
    for (const segmentKey of segmentKeys) {
      // Generate segment control information
      segmentControlData.push({
        label: capitalize(segmentKey),
        value: segmentKey,
      })

      // Generate job listing for each segment
      const jobForSegment = segmentedJobs[segmentKey]
      jobForSegment.forEach((job) => {
        const ldJson = {
          "@context": "http://schema.org",
          "@type": "JobPosting",
          hiringOrganization: "Norhart",
          jobBenefits:
            "Health Insurance, Life Insurance, Accidental Death Insurance, Retirement Planning, Unlimited Paid Time Off, Career Advancement, Industry Competitive Pay",
          datePosted: `${job.publishedOn}`,
          educationRequirements: `${job.education}`,
          experienceRequirements: `${job.experience}`,
          employmentType: `${job.employmentType}`,
          industry: `${job.industry}`,
          jobLocation: {
            "@type": "Place",
            address: {
              "@type": "PostalAddress",
              addressLocality: `${job.location?.city}`,
              addressRegion: `${job.location?.state}`,
            },
          },
          title: `${job.title}`,
          description: `${job.title}`,
        }

        const viewJobAction = () => {
          const jobReferrer = getCookie("jobReferrer")

          tagManager.pushOnce({
            event: `view-job`,
            jobReferrer: jobReferrer ?? "website",
            jobShortcode: job.shortcode,
            jobTitle: job.title,
          })

          window.open(job.shortlink, "_blank")
        }

        const listElement = (
          <li className="list-group-item d-flex justify-content-between align-items-center text-start text-start">
            <Helmet>
              <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
            </Helmet>

            <div className="container">
              <div className="row mt-3 mb-3">
                <div className="col-9 col-sm-9">
                  <span>
                    <div>
                      <NorhartDarkCircleIcon className="m-0 p-0" style={{ height: "30px", width: "30px" }} />
                    </div>
                    <strong className="careerTitle" style={{ overflowWrap: "break-word" }}>
                      {job.title}
                    </strong>
                    <br />
                    <span className="careerSubTitle">
                      <b>{job.location?.city}</b> • {job.location?.state} • {job.location?.country}
                    </span>
                    <br />
                    <span className="careerSubTitle">
                      {job.industry}: {job.employmentType}
                    </span>
                  </span>
                </div>

                <div className="col-3 col-sm-3">
                  <div className="d-grid">
                    <button
                      className="btn btn-primary btn-sm d-none d-md-block ms-5 mt-5"
                      style={{
                        backgroundColor: `#274F79`,
                        borderColor: `#274F79`,
                        zIndex: 100,
                      }}
                      onClick={viewJobAction}
                    >
                      View Job
                    </button>
                    <button
                      className="btn btn-primary btn-sm mt-5 d-block d-md-none"
                      style={{
                        backgroundColor: `#274F79`,
                        borderColor: `#274F79`,
                        zIndex: 100,
                      }}
                      onClick={viewJobAction}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )

        const listArray = segmentedJobsList[segmentKey] || []
        listArray.push(listElement)
        segmentedJobsList[segmentKey] = listArray
      })
    }
    return [segmentControlData, segmentedJobsList]
  }

  componentDidMount() {
    let url = "https://yoma58nvi0.execute-api.us-east-1.amazonaws.com/career/account-information"
    fetch(url, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((resp) => resp.json())
      .then((data) => {
        let jobs = data.jobs
        let segmentedJobs = this.segmentJobs(jobs)
        const [segmentControlData, segmentedJobsList] = this.generateUIDataFromSegmentedJobs(segmentedJobs)
        this.setState({
          isLoaded: true,
          segmentControlData,
          segmentedJobsList,
          jobs: segmentedJobsList["all"],
        })
      })
  }

  private setSegmentJobs(segmentValue: string) {
    this.setState({ ...this.state, jobs: this.state.segmentedJobsList[segmentValue] })
  }

  render() {
    let props = this.props
    return (
      <section className="container-fluid sectionBaseColor pt-5 pb-5">
        <div className="container pl-3 pr-3 text-center">
          <div className="container pb-5"></div>
          <div className="text-center pb3">
            <NorhartDarkCircleIcon />
          </div>
          <div
            className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl text-center"
            style={{ fontSize: "15px" }}
          >
            Norhart Careers
          </div>
          <hr
            className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line m4-5 mb-4"
            style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
          />
          <h3 className="contentHeroTitle">Be Part Of Our Story</h3>
          <h2 className="contentHeroSubTitle" style={{ color: "rgba(83,118,136,0.9)", opacity: "0.8" }}>
            Built into Norhart's DNA is the obsession to improve our customer's lives. We love elevating our people's
            skills, creativity, and drive to build "The Exceptional."
            <strong style={{ fontWeight: 600, color: "rgba(83,118,136,1.0)" }}> Join our team today!</strong>
          </h2>

          {this.state.isLoaded && (
            <div className="mb-3">
              <SegmentedControl
                items={this.state.segmentControlData}
                onChangeSegment={(value) => {
                  this.setSegmentJobs(value)
                }}
              />
            </div>
          )}
          <ul className="list-group pt-1">{this.state.jobs}</ul>
        </div>
      </section>
    )
  }
}

/** export */
export default CareerListingSection

import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import NewsletterSignup from "../components/static-sections/NewsletterSignupSection"
import BenefitsSection from "../components/static-sections/BenefitsSection"
import CareerListingSection from "../components/static-sections/CareerListingSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import GalleryImageSection from "../components/dynamic-sections/GalleryImageSection"
import FourImageTemplate from "../components/dynamic-sections/FourImageTemplate"
import TwoImageSection from "../components/dynamic-sections/TwoImageSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import SixBoxSection from "../components/dynamic-sections/SixBoxSection"
import HeroMiddleSection from "../components/dynamic-sections/HeroMiddleSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import ApartmentIcon from "../../assets/apartment-icon.svg"
import TeamUpIcon from "../../assets/team-up-icon.svg"
import GrowthIcon from "../../assets/growth-icon.svg"
import HeartIcon from "../../assets/heart-icon.svg"
import NorhartIcon from "../../assets/norhart-icon.svg"
import ValuesNorhartIcon from "../../assets/value-norhart-logo.svg"
import ValuesIcon from "../../assets/values.svg"
import ValuesRespectIcon from "../../assets/value-complete-respect.svg"
import ValuesResultsIcon from "../../assets/value-pursuit-results.svg"
import ValuesAttitudeIcon from "../../assets/value-attitude.svg"
import ValuesMindsetIcon from "../../assets/value-mindset.svg"
import ValuesReliableIcon from "../../assets/value-reliable.svg"
import StarReviewIcon from "../../assets/five-star-rating.svg"
import { getCookie } from "../utils/cookie"
import tagManager from "../utils/tag-manager"
import { useCookies } from "react-cookie"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const careersPage: React.FC<Props> = (props) => {
  const [cookies, setCookie] = useCookies(["jobReferrer"])

  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/careers",
          name: "Careers | Norhart",
          image: `${config.siteMetadata.siteUrl}/careers/norhart-careers-hero-open-graph.png`,
        },
      },
    ],
  }

  const jobReferrer = cookies.jobReferrer

  tagManager.pushOnce({
    event: `view-careers`,
    jobReferrer: jobReferrer ?? "website",
  })

  return (
    <Layout
      title="Careers | Norhart"
      description="Discover Norhart construction, technology, and management jobs in Forest Lake, Blaine, and Oakdale, MN. We love hiring people who want to change the world! Join our crew today and be one of us!"
      keywords="norhart, careers, construction, property managers, benefits, free benefits, employee benefits, health care, dental, vision, legal, maternity, paternity, childcare, forest lake, blaine, oakdale, minneapolis"
      imageTwitter={`${config.siteMetadata.siteUrl}/careers/norhart-careers-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/careers/norhart-careers-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroTopSection
        title="Join Our Crew"
        subtitle="We Hire People Who Change The World"
        imageTitle="Norhart Careers"
        image="/careers/norhart-careers-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Discover Norhart Jobs"
        tagLine="We Amplify Workplace Happiness & Growth"
        colorPalette={colorPalette}
      />

      <CareerListingSection colorPalette={colorPalette} />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Nohart Apartments"
        title="Current Projects"
        subtitle={`We invite you to check out our latest project in "Lexington Lofts" in Blaine, MN. At Norhart, our teams have designed and built an exceptional living experience from the top down!`}
        imageTitleOne="Lexington Lofts Image 1"
        imageOne="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-1.webp"
        imageTitleTwo="Lexington Lofts Image 2"
        imageTwo="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-2.webp"
        imageTitleThree="Lexington Lofts Image 3"
        imageThree="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-3.webp"
        imageTitleFour="Lexington Lofts Image 4"
        imageFour="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-4.webp"
        imageTitleFive="Lexington Lofts Image 5"
        imageFive="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-5.webp"
        imageTitleSix="Lexington Lofts Image 6"
        imageSix="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-6.webp"
        colorPalette={colorPalette}
        badge={false}
      />

      <SixBoxSection
        backgroundColorOne="#EFF2F4"
        backgroundColorTwo="#EFF2F4"
        boxIconFive={ValuesMindsetIcon}
        boxIconFour={ValuesAttitudeIcon}
        boxIconOne={ValuesNorhartIcon}
        boxIconSix={ValuesReliableIcon}
        boxIconThree={ValuesResultsIcon}
        boxIconTwo={ValuesRespectIcon}
        boxTitleOne="Norhart"
        boxSubTitleOne="This Is The Way"
        boxDescriptionOne="At Norhart, we hire, work, and live by our values. Our values are what defines and drives our culture at Norhart."
        boxTitleTwo="Value"
        boxSubTitleTwo="Achieve Great Things"
        boxDescriptionTwo="We lead from the front, love our work, we were born to do it; we accomplish far more than we thought possible."
        boxTitleThree="Value"
        boxSubTitleThree="Improve Every Day"
        boxDescriptionThree="We question the status quo;  We believe work shouldn't be complicated. We relentlessly simplify and automate our processes."
        boxTitleFour="Value"
        boxSubTitleFour="Be A Genuine Human"
        boxDescriptionFour="We are kind, supportive, and humble. We share credit, admit our mistakes and learn from them. We are open, honest, and vulnerable."
        boxTitleFive="Value"
        boxSubTitleFive="Resident Experience Obsession"
        boxDescriptionFive="We fight to make every resident touchpoint memorable and frictionless. We care about every little detail and its impact."
        boxTitleSix="Value"
        boxSubTitleSix="Level Yourself Up"
        boxDescriptionSix="We want to be the best in the world at what we do. We want to be so much better than the competition that the fight isn't fair."
        buttonColor="#34a5ff"
        buttonName="View #NorhartCulture"
        buttonText="#FFFFFF"
        buttonUrl="/norhart-life/"
        colorPalette={colorPalette}
        icon={ValuesIcon}
        subtitle="Knowing your team and company have your back is a great feeling! We are continually expanding our reach and setting our standards exceptionally high to elevate our organization and our people."
        title="Culture of Values"
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Free Benefits"
        title="We Offer Free Benefits!!!"
        subTitle="Health Care*, Vision, Dental, Legal, Maternity, Paternity, Paid Miltary Training Days, Unlimited PTO, and more!"
        image="/benefits/norhart-free-benefits-anvil-text.gif"
        imageAlt="Norhart Support Team"
        buttonName="View Norhart Benefits"
        buttonUrl="/benefits/"
        subNote="* Bronze Health Plan Only."
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroMiddleSection
        imageTitle="Norhart Free Benefits"
        image="/careers/norhart-free-benefits.png"
        colorPalette={colorPalette}
      />

      <HeroBottomSection
        backgroundColorOne="#F0F0F0"
        backgroundColorTwo="#F0F0F0"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"Norhart is committed to being the best. Our construction team is made up of great people! From top to bottom everyone is continually looking for ways to get even better at what they do. Our job site is a fun and respectful place for people to work." - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <FourImageTemplate
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        fontTextColor="#365c70"
        icon={HeartIcon}
        iconTitle="Love Your Job"
        title="Be One Of Us"
        subtitle="Our passion and approach is engaging in one-on-one human interaction with our people to amplify workplace happiness, growth, and fulfillment. Sounds awesome, right? Well it is!"
        imageTitleOne="Yes, Our Mission Is You"
        imageOneText="When you become part of the Norhart team you know you belong. Our purpose and belief is to elevate our people's potential, skill set, and mindset to achieve greatness both at work and in their personal life."
        imageOne="/careers/norhart-careers-our-mission-is-you.webp"
        imageOneAlt="Be Part Of The Norhart Team"
        imageOnePill=""
        imageTitleTwo="Diversity & Inclusion"
        imageTwoText="Plain and simple, our people drive the success of our business. These values are reflected in our commitment to developing an environment focused on equality, inclusion, empowerment, and respect."
        imageTwo="/careers/norhart-careers-diversity.webp"
        imageTwoAlt="Norhart Diversity and Inclusion"
        imageTwoPill=""
        imageTitleThree="We Take Care Of You"
        imageThreeText="We take care of you, from your financial well-being to your health. We continually invest in our talented people to help everyone achieve greatness at work and home. We truly believe our people are our most valuable asset."
        imageThree="/careers/norhart-careers-takes-care-of-you.webp"
        imageThreeAlt="Norhart Takes Care Of It's Employees"
        imageThreePill=""
        imageTitleFour="Growth Opportunities"
        imageFourText="We encourage our people to engage in continuous learning to grow professionally, focus on their goals, and spark new ideas. We believe in elevating and curating the best traits in our people to ensure success."
        imageFour="/careers/norhart-careers-continuous-learning.webp"
        imageFourAlt="Norhart Career Growth Opportunities"
        imageFourPill=""
        pillActive=""
        buttonUrl="/norhart-life/"
        buttonName="View #NorhartCulture"
        colorPalette={colorPalette}
        imageTopPad={0}
      />

      <TwoImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={ApartmentIcon}
        iconTitle="Norhart Roadmap"
        title="The Norhart Vision"
        subtitle={`Our "Prime Directive" is to build exceptional apartments that create a better way for people to live. We are hyper-focused on creating a best-in-class customer experience for our residents.`}
        imageTitleOne="Our Vision and Roadmap"
        imageOneText="Our 10-year roadmap simply is to be the largest smart apartment developer in the Greater Minneapolis/St. Paul area with a focus on lifestyle living, building community, and giving back."
        imageOne="/about/norhart-about-vision.webp"
        imageOneAlt="Norhart Vision and Roadmap"
        imageOnePill=""
        imageTitleTwo="Building Traction"
        imageTwoText="Norhart is bringing our vision to the ground by hiring top-notch employees, analyzing market data, becoming strong problem solvers, and defining processes to optimize our business."
        imageTwo="/about/norhart-about-traction.webp"
        imageTwoAlt="Norhart Traction"
        imageTwoPill=""
        pillActive={true}
        colorPalette={colorPalette}
        buttonName=""
        buttonUrl=""
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Code Of Conduct"
        title="Equal Opportunity Employer"
        subTitle="Norhart is an equal opportunity employer. All aspects of employment, including the decision to hire, promote, discipline, or discharge, will be based on merit, competence, performance, and business needs. We do not discriminate on the basis of race, color, religion, marital status, age, national origin, ancestry, physical or mental disability, medical condition, pregnancy, genetic information, gender, sexual orientation, gender identity or expression, veteran status, or any other status protected under federal, state, or local law."
        image="/careers/norhart-careers-equal-opportunity-employer.png"
        imageAlt="Norhart Equal Opportunity Employer"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <NewsletterSignup colorPalette={colorPalette} />

      <AwardsSection
        header="Norhart Playbook"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default careersPage
